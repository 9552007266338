.statcard {
  text-align: center;
  line-height: 0.2;
  width: 10rem;
  height: 10rem;
}

.statcard p {
  margin-bottom: 2.4rem;
  line-height: 1;
}

.statcard h1 {
  margin-top: -1rem;
}

.statcard h4 {
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
  color: #4688c7;
}
