.inputPassword {
  height: 34px;
  margin-top: 9px;
  padding-left: 10px;
  background-size: 0 !important;
  border: 1px solid #E3E9F3;
  border-radius: 2.5px;
  line-height: 34px;
  font-size: 13px;
  box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
}

.iconEyeSubWrapper {
  position: absolute;
  top: -26px;
  right: 27px;
  color: #9EA7B8;
}

.iconEyeSubWrapper:hover {
  color: black!important;
}


.iconEyeWrapper {
  position: relative;
}
