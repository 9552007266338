.authPage {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 35px 0;
  background: #fafafb;
  background-image: url("https://searchengineland.com/figz/wp-content/seloads/2015/07/lighthouse-beacon-ss-1920.jpg");
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  height: 221px;
  width: 685px;
  text-align: center;
  /* background-image: url('../../images/background_empty.svg'); */
  background-position-x: center;
  font-size: 14px;
}

.errorsContainer {
  margin-top: -21px;
  margin-bottom: 18px;
  color: #ff203c;
}

.headerContainer {
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
}

.headerContainer,
img {
  height: 40px;
}

.headerDescription {
  width: 416px;
  text-align: center;
  margin: auto;
  padding: 13px 30px 0 30px;
  line-height: 18px;
  color: #333740;
  font-size: 14px;
}

.formContainer {
  min-height: 200px;
  width: 416px;
  margin: 14px auto;
  margin-bottom: 0;
  padding: 33px 15px 15px 15px;
  border-radius: 2px;
  border-top: 2px solid #1c5de7;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #e3e9f3;
}

.loginButton {
  margin-top: -6px;
  padding-right: 0;
  text-align: right;
}

.loginButton,
button {
  margin-right: 16px;
}

.buttonContainer {
  padding-top: 3px;
}

.linkContainer {
  padding-top: 18px;
  > a {
    color: #262931;
    font-size: 13px;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: 0;
    }
  }
}

.linkContainer,
a {
  color: #262931;
  font-size: 13px;
}

.logoContainer {
  position: absolute;
  left: 30px;
  bottom: 30px;
}

.logoContainer,
img {
  height: 34px;
}

.separator {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background-color: rgba(14, 22, 34, 0.04);
}
