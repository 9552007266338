.inputText {
  height: 34px;
  margin-top: 9px;
  padding-left: 10px;
  background-size: 0 !important;
  border: 1px solid #E3E9F3;
  border-radius: 2.5px;
  line-height: 34px;
  font-size: 13px;
  font-family: 'Lato' !important;
  box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
}

/* .inputText:focus {
  border-color: #78caff;
  outline: 0;
  box-shadow: none !important;
} */
