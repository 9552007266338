body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "neo-sans";
  src: local("neo-sans"),
    url(./fonts/Neo-Sans-Std-Regular.otf) format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
